import { render, staticRenderFns } from "./BannerJoin.vue?vue&type=template&id=1f36e945&scoped=true"
import script from "./BannerJoin.vue?vue&type=script&lang=js"
export * from "./BannerJoin.vue?vue&type=script&lang=js"
import style0 from "./BannerJoin.vue?vue&type=style&index=0&id=1f36e945&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f36e945",
  null
  
)

export default component.exports