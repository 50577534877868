import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import WhatWeDo from './views/WhatWeDo.vue';
import AsianceStories from './views/AsianceStories.vue';
import PostView from './views/PostView.vue';
import PageView from './views/PageView.vue';
import AsianceNews from './views/AsianceNews.vue';
import WhoWeAre from './views/WhoWeAre.vue';
import JoinUs from './views/JoinUs.vue';
import ContactView from './views/ContactView.vue';
import NotFoundView from './views/NotFoundView.vue';
import CaseTahiti from './views/Case_tahiti.vue';
import CaseExotec from './views/Case_exotec.vue';
import CaseKagi from './views/Case_kagi.vue';
import CaseMansur from './views/Case_mansur.vue';
import CaseTumi from './views/Case_tumi.vue';
import CaseDior from './views/Case_dior.vue';
import CaseLV from './views/Case_lv.vue';
import CaseBottega from './views/Case_bottega.vue';
import CaseOnitsuka from './views/Case_onitsuka.vue';
import CaseValueRetail from './views/Case_valueretail.vue';
import CaseChanel from './views/Case_chanel.vue';
import CaseFinair from './views/Case_finair.vue';
import CaseTesla from './views/Case_tesla.vue';
import CasePfizer from './views/Case_pfizer.vue';
import CaseBvlgari from './views/Case_bvlgari.vue';
import Case3m from './views/Case_3m.vue';
import CaseLG from './views/Case_LG.vue';
import CaseBallantines from './views/Case_ballantines.vue';
import CaseNikon from './views/Case_nikon.vue';
import CaseLasVegas from './views/Case_lasVegas.vue';
import CaseMagnachip from './views/Case_magnachip.vue';
import CaseMoetHennessy from './views/Case_moethennessy.vue';
import CaseVacheron from './views/Case_vacheron.vue';
import CaseAesop from './views/Case_aesop.vue';
import HomeViewV2 from './views/HomeViewV2.vue';
import BrandTech from './views/BrandTech.vue';
import InsightReport from './views/InsightReport.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import App from './App.vue';

import { i18n } from './main';

Vue.use(VueRouter);
Vue.use(VueResource);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/what-we-do', redirect: () => `/${i18n.locale}/what-we-do` },
    {
      path: '/featured-stories',
      redirect: () => `/${i18n.locale}/featured-stories`,
    },
    { path: '/who-we-are', redirect: () => `/${i18n.locale}/who-we-are` },
    { path: '/join-us', redirect: () => `/${i18n.locale}/join-us` },
    { path: '/news', redirect: () => `/${i18n.locale}/news` },
    { path: '/contact', redirect: () => `/${i18n.locale}/contact` },
    {
      path: '/tahiti-tourism',
      redirect: () => `/${i18n.locale}/tahiti-tourism`,
    },
    {
      path: '/exotec',
      redirect: () => `/${i18n.locale}/exotec`,
    },
    {
      path: '/kagi',
      redirect: () => `/${i18n.locale}/kagi`,
    },
    {
      path: '/louis-vuitton',
      redirect: () => `/${i18n.locale}/louis-vuitton`,
    },
    {
      path: '/mansur-gavriel',
      redirect: () => `/${i18n.locale}/mansur-gavriel`,
    },
    { path: '/tumi', redirect: () => `/${i18n.locale}/tumi` },
    {
      path: '/vacheron-constantin',
      redirect: () => `/${i18n.locale}/vacheron-constantin`,
    },
    { path: '/aesop', redirect: () => `/${i18n.locale}/aesop` },
    { path: '/dior', redirect: () => `/${i18n.locale}/dior` },
    { path: '/home/dior', redirect: () => `/${i18n.locale}/dior` },
    {
      path: '/bottega-veneta',
      redirect: () => `/${i18n.locale}/bottega-veneta`,
    },
    {
      path: '/onitsuka-tiger',
      redirect: () => `/${i18n.locale}/onitsuka-tiger`,
    },
    { path: '/value-retail', redirect: () => `/${i18n.locale}/value-retail` },
    { path: '/chanel', redirect: () => `/${i18n.locale}/chanel` },
    { path: '/finnair', redirect: () => `/${i18n.locale}/finnair` },
    { path: '/tesla', redirect: () => `/${i18n.locale}/tesla` },
    { path: '/pfizer', redirect: () => `/${i18n.locale}/pfizer` },
    { path: '/bvlgari', redirect: () => `/${i18n.locale}/bvlgari` },
    { path: '/3m', redirect: () => `/${i18n.locale}/3m` },
    { path: '/lg', redirect: () => `/${i18n.locale}/lg` },
    { path: '/ballantines', redirect: () => `/${i18n.locale}/ballantines` },
    { path: '/nikon', redirect: () => `/${i18n.locale}/nikon` },
    { path: '/las-vegas', redirect: () => `/${i18n.locale}/las-vegas` },
    { path: '/magnachip', redirect: () => `/${i18n.locale}/magnachip` },
    { path: '/moet-hennessy', redirect: () => `/${i18n.locale}/moet-hennessy` },
    { path: '/brandtech', redirect: () => `/${i18n.locale}/brandtech` },
    { path: '/insightreport', redirect: () => `/${i18n.locale}/insightreport` },
    {
      path: '/privacy-policy',
      redirect: () => `/${i18n.locale}/privacy-policy`,
    },
    {
      path: '/:lang',
      component: App,
      beforeEnter(to, from, next) {
        const lang = to.params.lang;
        if (!['en', 'ko', 'ja'].includes(lang)) return next('en');
        if (i18n.locale !== lang) {
          i18n.locale = lang;
        }
        return next();
      },
      children: [
        { name: 'home', path: '/', component: HomeViewV2 },
        {
          name: 'what_we_do',
          path: 'what-we-do',
          component: WhatWeDo,
          params: { pageName: 'Case' },
          children: [
            {
              path: ':slug',
              meta: {
                showModal: true,
              },
            },
          ],
        },
        {
          name: 'stories',
          path: 'featured-stories',
          component: AsianceStories,
        },
        { name: 'who_we_are', path: 'who-we-are', component: WhoWeAre },
        { name: 'join_us', path: 'join-us', component: JoinUs },
        { name: 'news', path: 'news', component: AsianceNews },
        { name: 'contact', path: 'contact', component: ContactView },
        { name: 'louis-vuitton', path: 'louis-vuitton', component: CaseLV },
        {
          name: 'tahiti-tourism',
          path: 'tahiti-tourism',
          component: CaseTahiti,
        },
        {
          name: 'exotec',
          path: 'exotec',
          component: CaseExotec,
        },
        {
          name: 'kagi',
          path: 'kagi',
          component: CaseKagi,
        },
        {
          name: 'mansur-gavriel',
          path: 'mansur-gavriel',
          component: CaseMansur,
        },
        { name: 'tumi', path: 'tumi', component: CaseTumi },
        {
          name: 'vacheron-constantin',
          path: 'vacheron-constantin',
          component: CaseVacheron,
        },
        { name: 'aesop', path: 'aesop', component: CaseAesop },
        { name: 'dior', path: 'dior', component: CaseDior },
        {
          name: 'bottega-veneta',
          path: 'bottega-veneta',
          component: CaseBottega,
        },
        {
          name: 'onitsuka-tiger',
          path: 'onitsuka-tiger',
          component: CaseOnitsuka,
        },
        {
          name: 'value-retail',
          path: 'value-retail',
          component: CaseValueRetail,
        },
        { name: 'chanel', path: 'chanel', component: CaseChanel },
        { name: 'finnair', path: 'finnair', component: CaseFinair },
        { name: 'tesla', path: 'tesla', component: CaseTesla },
        { name: 'pfizer', path: 'pfizer', component: CasePfizer },
        { name: 'bvlgari', path: 'bvlgari', component: CaseBvlgari },
        { name: '3m', path: '3m', component: Case3m },
        { name: 'lg', path: 'lg', component: CaseLG },
        {
          name: 'ballantines',
          path: 'ballantines',
          component: CaseBallantines,
        },
        { name: 'nikon', path: 'nikon', component: CaseNikon },
        { name: 'las-vegas', path: 'las-vegas', component: CaseLasVegas },
        { name: 'magnachip', path: 'magnachip', component: CaseMagnachip },
        {
          name: 'moet-hennessy',
          path: 'moet-hennessy',
          component: CaseMoetHennessy,
        },
        {
          name: 'brandtech',
          path: 'brandtech',
          component: BrandTech,
          beforeEnter(to, from, next) {
            document.head.querySelector(
              "[property~='og:image'][content]"
            ).content = `${window.location.origin}img/fb-share-brandtech.png`;
            document.head.querySelector(
              "[name~='twitter:image:src'][content]"
            ).content = `${window.location.origin}img/fb-share-brandtech.png`;
            return next();
          },
        },
        {
          name: 'insightreport',
          path: 'insightreport',
          component: InsightReport,
        },
        {
          name: 'privacy-policy',
          path: 'privacy-policy',
          component: PrivacyPolicy,
        },
        { name: 'post', path: ':slug', component: PostView },
        { name: 'page', path: ':slug', component: PageView },
      ],
    },
    { path: '*', component: NotFoundView },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          x: 0,
          y: 80,
        },
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const browserLanguage =
    navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0];
  const defaultLanguage = 'en';
  const supportedLanguages = ['en', 'ko', 'ja'];

  // Get language from the URL
  const routeLanguage = to.params.lang;

  // Redirect if no language is set in the URL or if the language is unsupported
  if (!routeLanguage || !supportedLanguages.includes(routeLanguage)) {
    const languageToUse = supportedLanguages.includes(browserLanguage)
      ? browserLanguage
      : defaultLanguage;
    return next(`/${languageToUse}${to.fullPath}`);
  }

  next();
});

export default router;
